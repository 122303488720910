import React from "react";

type Props = {
  children?: any;
  title: string;
  description: string;
  url: string;
};

function SEO(props: Props) {
  const imageURL = "/ogp.png";

  return (
    <React.Fragment>
      <title>{props.title}</title>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1"
      ></meta>
      <meta name="description" content={props.description} />
      <meta name="og:title" content={props.title} />
      <meta name="og:url" content={props.url} />
      <meta name="og:description" content={props.description} />
      <meta name="og:site_name" content={process.env.GATSBY_SITE_TITLE} />
      <meta
        name="og:image"
        content={`${process.env.GATSBY_SITE_URL}${imageURL}`}
      />
      <meta
        name="twitter:image"
        content={`${process.env.GATSBY_SITE_URL}${imageURL}`}
      />
      <meta name="og:type" content="website" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@artefactjp" />
      <meta name="format-detection" content="telephone=no" />
      <link
        rel="canonical"
        href={`${process.env.GATSBY_SITE_URL}${props.url}`}
      />
      {props.children}
    </React.Fragment>
  );
}

export default SEO;
