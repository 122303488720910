import React, { useEffect } from "react";
import cx from "classnames";
import classNames from "classnames";

type Props = {
  children: JSX.Element | JSX.Element[];
  className?: string;
};

function Frame({ children, className }: Props) {
  return (
    <div className={cx("min-w-[375px] font-base", className)}>
      <React.Fragment>{children}</React.Fragment>
    </div>
  );
}

export default Frame;
