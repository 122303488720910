import React from "react";
import cx from "classnames";
import { Link } from "gatsby";

type Props = {
  className?: string;
};

function Navigation({ className }: Props) {
  return (
    <div
      className={cx(
        "flex flex-row md:flex-col gap-4 sm:gap-8 py-7 md:justify-center md:border-l-[0.5px] border-white md:pl-10"
      )}
    >
      <Link
        to="/"
        className="text-gray tracking-[2px] text-sm sm:text-base"
        activeClassName="text-white"
      >
        HOME
      </Link>
      <Link
        to="/about_us/"
        className="text-gray tracking-[2px] text-sm sm:text-base"
        activeClassName="text-white"
      >
        ABOUT
      </Link>
      <Link
        to="/contact/"
        className="text-gray tracking-[2px] text-sm sm:text-base"
        activeClassName="text-white"
      >
        CONTACT
      </Link>
      <a href="/jobs/" target="_blank" className="text-gray tracking-[2px]">
        JOBS
      </a>
    </div>
  );
}

export default Navigation;
