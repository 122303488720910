import React from "react";

import SEO from "~/helpers/seo";
import Frame from "~/interface/layout/frame";
import Navigation from "~/interface/compositions/navigation";
import Footer from "~/interface/compositions/footer";

const NotFoundPage = () => {
  return (
    <Frame className="bg-black text-white ">
      <div className=" h-screen flex justify-between">
        <div className="h-full px-10 sm:px-20 md:px-[128px]">
          <div className="flex items-center h-[calc(100%-84px)]">
            <div>
              <div>
                <div className="mb-5 text-9xl font-bold text-white">
                  404 Not Found
                </div>
                <h1 className="mb-12 text-lg">
                  お探しのページが見つかりません
                </h1>
                <p className="text-lg font-regular tracking-[0.06em] leading-[34px]">
                  ページが移動・削除された可能性があります。
                </p>
              </div>
            </div>
          </div>

          <Navigation />
        </div>
      </div>

      <Footer />
    </Frame>
  );
};

export default NotFoundPage;

export const Head = () => {
  return (
    <SEO
      title="ページが見つかりませんでした | 株式会社Artefact"
      description="株式会社Artefactは東京に拠点を置く、デジタル戦略とデザインを専門としたプロダクトスタジオです。「デジタルデザイナーの毎日をもっと楽しく」を目指してWebマガジン「unprinted」を運営しています。"
      url="/"
    />
  );
};
