import React from "react";
import cx from "classnames";

type Props = {
  className?: string;
};

function Footer({ className }: Props) {
  return (
    <div
      className={cx(
        className,
        "flex flex-col items-center md:items-end gap-5 py-10 px-[68px] border-t-[0.5px] border-white bg-black"
      )}
    >
      <p className="text-center md:text-right text-2xs leading-[26px]">
        東京都目黒区駒場4-6-2 Y-5 yamagataya 501
        <br /> 03-4588-9508, 株式会社Artefact
      </p>
      <p className="text-center md:text-right text-gray-darker text-3xs">
        Copyright © Artefact Inc. All rights Reserved.
      </p>
    </div>
  );
}

export default Footer;
